<template>
    <div class="main-content-area">
        <div class="main-content-toolbar">
            <v-toolbar></v-toolbar>
            <v-toolbar>
                <v-toolbar-title class="text-center">
                    <div class="toolbar-title">
                        <h3>{{ $t('truckerGate') }}</h3>
                        <span class="job-status">{{ $t('manualOperations') }}</span>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-toolbar></v-toolbar>
        </div>

        <div class="center-flex" v-if="!ready">
            <DataLoading :type="4" />
        </div>

        <div v-if="ready" class="main-content-playground">
            <!-- First Row: Gate In Button -->
            <div class="gate-in">
                <v-row>
                    <v-col class="rowBtnCol">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            @click="gateIn">
                            <v-icon class="btn-icon" color="green">mdi-truck-delivery</v-icon>
                            <span class="container-btn-text">
                                {{ $t('gateIn') }}
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div>&nbsp;</div>

            <!-- Second Row: Gate Out Button -->
            <div class="gate-out">
                <v-row>
                    <v-col class="rowBtnCol">
                        <v-btn color="primary" size="x-large" width="100%" height="100%" class="white--text"
                            @click="gateOut">
                            <v-icon class="btn-icon" color="red" style="transform: scaleX(-1)">
                                mdi-truck-delivery
                            </v-icon>
                            <span class="container-btn-text">
                                {{ $t('gateOut') }}
                            </span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>

            <div>&nbsp;</div>

            <!-- Third Row: Language Selection with Country Flags -->
            <div class="flags">
                <v-row justify="center" align="center">
                    <v-col v-for="(language, index) in languages" :key="index" class="flag-col" cols="4" sm="2">
                        <v-btn icon variant="text" @click="changeLanguage(language.code)" class="flag-btn">
                            <v-img :src="language.flag" :alt="language.name" class="flag-icon" />
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div class="main-content-footer">
            <AppMessageNotifications />
            <v-toolbar :height="40">
                <v-btn icon="mdi-circle" size="small" color="light-green darken-1" variant="text"></v-btn>
                <v-btn size="x-small" variant="text" @click="reloadList">
                    <h2>Status: online</h2>
                </v-btn>
            </v-toolbar>
        </div>
    </div>
</template>

<script setup>
import { ref, onActivated } from 'vue';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { useI18n } from 'vue-i18n'; // For localization

// Reactive Variables
const ready = ref(false);

// Router
const router = useRouter();

// Use vue-i18n for localization
const { locale, t } = useI18n();

// Language Options
const languages = [
    {
        code: 'en',
        name: 'English',
        flag: require('@/assets/flags/en.png'),
    },
    {
        code: 'de',
        name: 'German',
        flag: require('@/assets/flags/de.png'),
    },
    {
        code: 'cz',
        name: 'Czech',
        flag: require('@/assets/flags/cz.png'),
    },
    {
        code: 'ru',
        name: 'Russian',
        flag: require('@/assets/flags/ru.png'),
    },
    {
        code: 'pl',
        name: 'Polish',
        flag: require('@/assets/flags/pl.png'),
    },
    {
        code: 'ro',
        name: 'Romanian',
        flag: require('@/assets/flags/ro.png'),
    },
];

// Store
const store = useAppStore();

// Methods
const changeLanguage = (langCode) => {
    locale.value = langCode;
    store.addNotificationMessage(
        `${t('languageChanged')} ${langCode.toUpperCase()}`,
        'success'
    );
};

const gateIn = () => {
    router.push({ name: 'TgAppGateIn' });
};

const gateOut = () => {
    router.push({ name: 'TgAppGateOut' });
};

const reloadList = () => {
    // Logic to reload the list or refresh data
};

onActivated(() => {
    ready.value = true;
});
</script>

<style scoped>
.main-content-playground {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.gate-in,
.gate-out {
    display: flex;
    flex-grow: 1;
}

.rowBtnCol {
    width: 100%;
    height: 100%;
    display: flex;
}

.rowBtnCol .v-btn {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container-btn-text,
.position-label,
.position-value,
.action-btn-text {
    font-weight: bold;
    font-size: 2.5em;
}

.btn-icon {
    font-size: 5em;
}

.toolbar-title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: #ffffff;
}

.job-status {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 1px;
    color: #ffffff;
    margin-left: 8px;
}

.flags {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.flag-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.flag-btn {
  padding: 0;
  min-width: 56px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.flag-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>