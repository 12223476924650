import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    truckerGate: 'TRUCKER GATE',
    gateIn: 'Gate In',
    gateOut: 'Gate Out',
    reference: 'Reference',
    selectReference: 'Select Reference',
    containerNumber: 'Container Number',
    selectContainerNumber: 'Select Container Number',
    isoCode: 'ISO Code',
    selectIsoCode: 'Select ISO Code',
    customerOperator: 'Customer / Operator',
    selectCustomer: 'Select Customer',
    truckerCompany: 'Trucker Company',
    selectTruckerCompany: 'Select Trucker Company',
    licencePlate: 'Licence Plate',
    selectLicencePlate: 'Enter Licence Plate',
    confirmGateIn: 'Confirm Gate In',
    confirmGateOut: 'Confirm Gate Out',
    statusOnline: 'Status: online',
    goBack: 'Go back',
    enterReference: 'Enter Reference',
    submitReference: 'Submit Reference',
    enterContainerNumber: 'Enter Container Number',
    submitContainer: 'Submit Container',
    gateInConfirmed: 'Gate In confirmed.',
    gateOutConfirmed: 'Gate Out confirmed.',
    wrongReference: 'Wrong reference',
    completeAllSelections: 'Please complete all required selections.',
    containerWrongCheckDigit: 'Container number {containerNumber} has wrong check digit',
    invalidContainerFormat: 'Invalid container number format',
    errorValidatingReference: 'Error validating reference',
    pleaseSelectContainer: 'Please select a container.',
    moveCompleted: 'Move completed.',
    taskCompleted: 'Task Completed',
    containerNumberHasWrongCheckDigit: 'Container number {containerNumber} has wrong check digit',
    containerNumberSelectContainer: 'Select Container',
    manualOperations: 'Manual Operations',
    languageChanged: 'Language changed to',
    truckDelivery: 'mdi-truck-delivery',
    statusOffline: 'Status: offline',
    enterLicencePlate: 'Enter Licence Plate',
    submitLicencePlate: 'Submit Licence Plate',
    enterIsoCode: 'Enter ISO Code',
    submitIsoCode: 'Submit ISO Code',
    enterCustomer: 'Enter Customer',
    submitCustomer: 'Submit Customer',
    enterTruckerCompany: 'Enter Trucker Company',
    submitTruckerCompany: 'Submit Trucker Company',
    nonNumericalRelease: 'Non-numerical Release',
    filterContainers: 'Filter Containers',
    filterTruckerCompany: 'Filter Trucker Company',
    addNewTruckerCompany: 'Add New Trucker Company',
    errorConfirmingGateOut: 'Error confirming Gate Out',
    taskAddedSuccessfully: 'Task added successfully.',
    errorAddingTask: 'Error adding task',
    referenceNotFound: 'Reference not found',
    errorFetchingAvailableContainers: 'Error fetching available containers',
    errorFetchingData: 'Error fetching data',
    errorAddingTruckerCompany: 'Error adding Trucker Company',
    truckerCompanyNameRequired: 'Trucker Company name is required',
    truckerCompanyEmailRequired: 'Trucker Company email is required',
    invalidEmail: 'Invalid email address',
    containerNotInStock: 'Container not in stock',
    errorCheckingContainer: 'Error checking container',
    infoGateOutContainer: 'Container unit number is only selected for numerical releases',
  },
  de: {
    truckerGate: 'Trucker Gate',
    gateIn: 'Gate-In',
    gateOut: 'Gate-Out',
    reference: 'Referenz',
    selectReference: 'Referenz auswählen',
    containerNumber: 'Container Nummer',
    selectContainerNumber: 'Container Nummer auswählen',
    isoCode: 'ISO Code',
    selectIsoCode: 'ISO Code auswählen',
    customerOperator: 'Kunde / Reederei',
    selectCustomer: 'Kunde / Reederei auswählen',
    truckerCompany: 'Spedition',
    selectTruckerCompany: 'Spedition auswählen',
    licencePlate: 'Kennzeichen',
    selectLicencePlate: 'Kennzeichen eingeben',
    confirmGateIn: 'Gate-In bestätigen',
    confirmGateOut: 'Gate-Out bestätigen',
    statusOnline: 'Status: online',
    goBack: 'Zurück',
    enterReference: 'Referenz eingeben',
    submitReference: 'Referenz absenden',
    enterContainerNumber: 'Container Nummer eingeben',
    submitContainer: 'Container absenden',
    gateInConfirmed: 'Gate-In bestätigt.',
    gateOutConfirmed: 'Gate-Out bestätigt.',
    wrongReference: 'Falsche Referenz',
    completeAllSelections: 'Bitte alle erforderlichen Angaben vornehmen.',
    containerWrongCheckDigit: 'Container Nummer {containerNumber} hat eine falsche Prüfziffer',
    invalidContainerFormat: 'Ungültiges Container Nummer Format',
    errorValidatingReference: 'Fehler bei der Validierung der Referenz',
    pleaseSelectContainer: 'Bitte wählen Sie einen Container aus.',
    moveCompleted: 'Fahrauftrag abgeschlossen.',
    taskCompleted: 'Aufgabe abgeschlossen',
    containerNumberHasWrongCheckDigit: 'Container Nummer {containerNumber} hat eine falsche Prüfziffer',
    containerNumberSelectContainer: 'Container auswählen',
    manualOperations: 'Manuelle Operationen',
    languageChanged: 'Sprache geändert zu',
    truckDelivery: 'mdi-truck-delivery',
    statusOffline: 'Status: offline',
    enterLicencePlate: 'Kennzeichen eingeben',
    submitLicencePlate: 'Kennzeichen absenden',
    enterIsoCode: 'ISO Code eingeben',
    submitIsoCode: 'ISO Code absenden',
    enterCustomer: 'Kunden / Reederei eingeben',
    submitCustomer: 'Kunden / Reederei absenden',
    enterTruckerCompany: 'Spedition eingeben',
    submitTruckerCompany: 'Spedition absenden',
    nonNumericalRelease: 'Nicht-numerische Freistellung',
    filterContainers: 'Container filtern',
    filterTruckerCompany: 'Spedition filtern',
    addNewTruckerCompany: 'Neue Spedition hinzufügen',
    errorConfirmingGateOut: 'Fehler beim Bestätigen der Ausfahrt',
    taskAddedSuccessfully: 'Fahrauftrag erfolgreich hinzugefügt.',
    errorAddingTask: 'Fehler beim Hinzufügen des Fahrauftrags',
    referenceNotFound: 'Referenz nicht gefunden',
    errorFetchingAvailableContainers: 'Fehler beim Abrufen verfügbarer Container',
    errorFetchingData: 'Fehler beim Abrufen der Daten',
    errorAddingTruckerCompany: 'Fehler beim Hinzufügen der Spedition',
    truckerCompanyNameRequired: 'Name der Spedition ist erforderlich',
    truckerCompanyEmailRequired: 'E-Mail der Spedition ist erforderlich',
    invalidEmail: 'Ungültige E-Mail-Adresse',
    containerNotInStock: 'Container nicht im Depot',
    errorCheckingContainer: 'Fehler beim Überprüfen des Containers',
    infoGateOutContainer: 'Container Nummer wird nur für numerische Freistellungen ausgewählt',
  },
  cz: {
    "truckerGate": "BRÁNA PRO ŘIDIČE",
    "gateIn": "Vjezd",
    "gateOut": "Výjezd",
    "reference": "Reference",
    "selectReference": "Vyberte referenci",
    "containerNumber": "Číslo kontejneru",
    "selectContainerNumber": "Vyberte číslo kontejneru",
    "isoCode": "ISO Kód",
    "selectIsoCode": "Vyberte ISO kód",
    "customerOperator": "Zákazník / Operátor",
    "selectCustomer": "Vyberte zákazníka",
    "truckerCompany": "Dopravní společnost",
    "selectTruckerCompany": "Vyberte dopravní společnost",
    "licencePlate": "SPZ",
    "selectLicencePlate": "Zadejte SPZ",
    "confirmGateIn": "Potvrdit vjezd",
    "confirmGateOut": "Potvrdit výjezd",
    "statusOnline": "Stav: online",
    "goBack": "Zpět",
    "enterReference": "Zadejte referenci",
    "submitReference": "Odeslat referenci",
    "enterContainerNumber": "Zadejte číslo kontejneru",
    "submitContainer": "Odeslat kontejner",
    "gateInConfirmed": "Vjezd potvrzen.",
    "gateOutConfirmed": "Výjezd potvrzen.",
    "wrongReference": "Nesprávná reference",
    "completeAllSelections": "Vyplňte prosím všechny požadované údaje.",
    "containerWrongCheckDigit": "Číslo kontejneru {containerNumber} má špatnou kontrolní číslici",
    "invalidContainerFormat": "Neplatný formát čísla kontejneru",
    "errorValidatingReference": "Chyba při ověřování reference",
    "pleaseSelectContainer": "Vyberte prosím kontejner.",
    "moveCompleted": "Pohyb dokončen.",
    "taskCompleted": "Úkol dokončen",
    "containerNumberHasWrongCheckDigit": "Číslo kontejneru {containerNumber} má špatnou kontrolní číslici",
    "containerNumberSelectContainer": "Vyberte kontejner",
    "manualOperations": "Manuální operace",
    "languageChanged": "Jazyk změněn na",
    "truckDelivery": "mdi-truck-delivery",
    "statusOffline": "Stav: offline",
    "enterLicencePlate": "Zadejte SPZ",
    "submitLicencePlate": "Odeslat SPZ",
    "enterIsoCode": "Zadejte ISO kód",
    "submitIsoCode": "Odeslat ISO kód",
    "enterCustomer": "Zadejte zákazníka",
    "submitCustomer": "Odeslat zákazníka",
    "enterTruckerCompany": "Zadejte dopravní společnost",
    "submitTruckerCompany": "Odeslat dopravní společnost",
    "nonNumericalRelease": "Nenumerické vydání",
    "filterContainers": "Filtrovat kontejnery",
    "filterTruckerCompany": "Filtrovat dopravní společnosti",
    "addNewTruckerCompany": "Přidat novou dopravní společnost",
    "errorConfirmingGateOut": "Chyba při potvrzování výjezdu",
    "taskAddedSuccessfully": "Úkol úspěšně přidán.",
    "errorAddingTask": "Chyba při přidávání úkolu",
    "referenceNotFound": "Reference nenalezena",
    "errorFetchingAvailableContainers": "Chyba při načítání dostupných kontejnerů",
    "errorFetchingData": "Chyba při načítání dat",
    "errorAddingTruckerCompany": "Chyba při přidávání dopravní společnosti",
    "truckerCompanyNameRequired": "Název dopravní společnosti je povinný",
    "truckerCompanyEmailRequired": "E-mail dopravní společnosti je povinný",
    "invalidEmail": "Neplatná e-mailová adresa",
    "containerNotInStock": "Kontejner není na skladě",
    "errorCheckingContainer": "Chyba při kontrole kontejneru",
    "infoGateOutContainer": "Číslo jednotky kontejneru je vybráno pouze pro numerická vydání"
  },
  ru: {
    truckerGate: 'ГРУЗОВЫЕ ВОРОТА',
    gateIn: 'Въезд',
    gateOut: 'Выезд',
    reference: 'Референс',
    selectReference: 'Выбрать референс',
    containerNumber: 'Номер контейнера',
    selectContainerNumber: 'Выбрать номер контейнера',
    isoCode: 'ISO код',
    selectIsoCode: 'Выбрать ISO код',
    customerOperator: 'Клиент / Оператор',
    selectCustomer: 'Выбрать клиента',
    truckerCompany: 'Транспортная компания',
    selectTruckerCompany: 'Выбрать транспортную компанию',
    licencePlate: 'Номерной знак',
    selectLicencePlate: 'Ввести номерной знак',
    confirmGateIn: 'Подтвердить въезд',
    confirmGateOut: 'Подтвердить выезд',
    statusOnline: 'Статус: онлайн',
    goBack: 'Назад',
    enterReference: 'Введите референс',
    submitReference: 'Отправить референс',
    enterContainerNumber: 'Введите номер контейнера',
    submitContainer: 'Отправить контейнер',
    gateInConfirmed: 'Въезд подтвержден.',
    gateOutConfirmed: 'Выезд подтверждён.',
    wrongReference: 'Неверный референс',
    completeAllSelections: 'Пожалуйста, заполните все необходимые поля.',
    containerWrongCheckDigit: 'Номер контейнера {containerNumber} имеет неправильную контрольную цифру',
    invalidContainerFormat: 'Неверный формат номера контейнера',
    errorValidatingReference: 'Ошибка при проверке референса',
    pleaseSelectContainer: 'Пожалуйста, выберите контейнер.',
    moveCompleted: 'Перемещение завершено.',
    taskCompleted: 'Задача выполнена',
    containerNumberHasWrongCheckDigit: 'Номер контейнера {containerNumber} имеет неправильную контрольную цифру',
    containerNumberSelectContainer: 'Выбрать контейнер',
    manualOperations: 'Ручные операции',
    languageChanged: 'Язык изменен на',
    truckDelivery: 'mdi-truck-delivery',
    statusOffline: 'Статус: офлайн',
    enterLicencePlate: 'Введите номерной знак',
    submitLicencePlate: 'Отправить номерной знак',
    enterIsoCode: 'Введите ISO код',
    submitIsoCode: 'Отправить ISO код',
    enterCustomer: 'Введите клиента',
    submitCustomer: 'Отправить клиента',
    enterTruckerCompany: 'Введите транспортную компанию',
    submitTruckerCompany: 'Отправить транспортную компанию',
    nonNumericalRelease: 'Ненумерованное разрешение',
    filterContainers: 'Фильтр контейнеров',
    filterTruckerCompany: 'Фильтр транспортных компаний',
    addNewTruckerCompany: 'Добавить новую транспортную компанию',
    errorConfirmingGateOut: 'Ошибка подтверждения выезда',
    taskAddedSuccessfully: 'Задача успешно добавлена.',
    errorAddingTask: 'Ошибка при добавлении задачи',
    referenceNotFound: 'Референс не найден',
    errorFetchingAvailableContainers: 'Ошибка при получении доступных контейнеров',
    errorFetchingData: 'Ошибка при получении данных',
    errorAddingTruckerCompany: 'Ошибка при добавлении транспортной компании',
    truckerCompanyNameRequired: 'Требуется название транспортной компании',
    truckerCompanyEmailRequired: 'Требуется электронная почта транспортной компании',
    invalidEmail: 'Неверный адрес электронной почты',
    containerNotInStock: 'Контейнер отсутствует на складе',
    errorCheckingContainer: 'Ошибка при проверке контейнера',
    infoGateOutContainer: 'Номер контейнера выбирается только для числовых выпусков',
  },
  pl: {
    truckerGate: 'BRAMA DLA CIĘŻARÓWEK',
    gateIn: 'Wjazd',
    gateOut: 'Wyjazd',
    reference: 'Referencja',
    selectReference: 'Wybierz referencję',
    containerNumber: 'Numer kontenera',
    selectContainerNumber: 'Wybierz numer kontenera',
    isoCode: 'Kod ISO',
    selectIsoCode: 'Wybierz kod ISO',
    customerOperator: 'Klient / Operator',
    selectCustomer: 'Wybierz klienta',
    truckerCompany: 'Firma transportowa',
    selectTruckerCompany: 'Wybierz firmę transportową',
    licencePlate: 'Tablica rejestracyjna',
    selectLicencePlate: 'Wprowadź tablicę rejestracyjną',
    confirmGateIn: 'Potwierdź wjazd',
    confirmGateOut: 'Potwierdź wyjazd',
    statusOnline: 'Status: online',
    goBack: 'Powrót',
    enterReference: 'Wprowadź referencję',
    submitReference: 'Zatwierdź referencję',
    enterContainerNumber: 'Wprowadź numer kontenera',
    submitContainer: 'Zatwierdź kontener',
    gateInConfirmed: 'Wjazd potwierdzony.',
    gateOutConfirmed: 'Wyjazd potwierdzony.',
    wrongReference: 'Nieprawidłowa referencja',
    completeAllSelections: 'Proszę uzupełnić wszystkie wymagane pola.',
    containerWrongCheckDigit: 'Numer kontenera {containerNumber} ma błędną cyfrę kontrolną',
    invalidContainerFormat: 'Nieprawidłowy format numeru kontenera',
    errorValidatingReference: 'Błąd podczas walidacji referencji',
    pleaseSelectContainer: 'Proszę wybrać kontener.',
    moveCompleted: 'Przeniesienie zakończone.',
    taskCompleted: 'Zadanie ukończone',
    containerNumberHasWrongCheckDigit: 'Numer kontenera {containerNumber} ma błędną cyfrę kontrolną',
    containerNumberSelectContainer: 'Wybierz kontener',
    manualOperations: 'Operacje ręczne',
    languageChanged: 'Język zmieniony na',
    truckDelivery: 'mdi-truck-delivery',
    statusOffline: 'Status: offline',
    enterLicencePlate: 'Wprowadź tablicę rejestracyjną',
    submitLicencePlate: 'Zatwierdź tablicę rejestracyjną',
    enterIsoCode: 'Wprowadź kod ISO',
    submitIsoCode: 'Zatwierdź kod ISO',
    enterCustomer: 'Wprowadź klienta',
    submitCustomer: 'Zatwierdź klienta',
    enterTruckerCompany: 'Wprowadź firmę transportową',
    submitTruckerCompany: 'Zatwierdź firmę transportową',
    nonNumericalRelease: 'Zwolnienie nienumerowane',
    filterContainers: 'Filtruj kontenery',
    filterTruckerCompany: 'Filtruj firmę transportową',
    addNewTruckerCompany: 'Dodaj nową firmę transportową',
    errorConfirmingGateOut: 'Błąd podczas potwierdzania wyjazdu',
    taskAddedSuccessfully: 'Zadanie dodane pomyślnie.',
    errorAddingTask: 'Błąd podczas dodawania zadania',
    referenceNotFound: 'Nie znaleziono referencji',
    errorFetchingAvailableContainers: 'Błąd podczas pobierania dostępnych kontenerów',
    errorFetchingData: 'Błąd podczas pobierania danych',
    errorAddingTruckerCompany: 'Błąd podczas dodawania firmy transportowej',
    truckerCompanyNameRequired: 'Nazwa firmy transportowej jest wymagana',
    truckerCompanyEmailRequired: 'Email firmy transportowej jest wymagany',
    invalidEmail: 'Nieprawidłowy adres email',
    containerNotInStock: 'Kontener niedostępny',
    errorCheckingContainer: 'Błąd podczas sprawdzania kontenera',
    infoGateOutContainer: 'Numer kontenera jest wybierany tylko dla zwolnień numerowanych',
  },
  ro: {
    truckerGate: 'POARTA CAMION',
    gateIn: 'Intrare',
    gateOut: 'Ieşire',
    reference: 'Referință',
    selectReference: 'Selectați referința',
    containerNumber: 'Număr container',
    selectContainerNumber: 'Selectați numărul containerului',
    isoCode: 'Cod ISO',
    selectIsoCode: 'Selectați codul ISO',
    customerOperator: 'Client / Operator',
    selectCustomer: 'Selectați clientul',
    truckerCompany: 'Companie de transport',
    selectTruckerCompany: 'Selectați compania de transport',
    licencePlate: 'Număr de înmatriculare',
    selectLicencePlate: 'Introduceți numărul de înmatriculare',
    confirmGateIn: 'Confirmați intrarea',
    confirmGateOut: 'Confirmați ieșirea',
    statusOnline: 'Status: online',
    goBack: 'Înapoi',
    enterReference: 'Introduceți referința',
    submitReference: 'Trimiteți referința',
    enterContainerNumber: 'Introduceți numărul containerului',
    submitContainer: 'Trimiteți containerul',
    gateInConfirmed: 'Intrare confirmată.',
    gateOutConfirmed: 'Ieșirea confirmată.',
    wrongReference: 'Referință incorectă',
    completeAllSelections: 'Vă rugăm să completați toate selecțiile necesare.',
    containerWrongCheckDigit: 'Numărul containerului {containerNumber} are o cifră de control incorectă',
    invalidContainerFormat: 'Format invalid al numărului containerului',
    errorValidatingReference: 'Eroare la validarea referinței',
    pleaseSelectContainer: 'Vă rugăm să selectați un container.',
    moveCompleted: 'Mutare completată.',
    taskCompleted: 'Sarcină finalizată',
    containerNumberHasWrongCheckDigit: 'Numărul containerului {containerNumber} are o cifră de control incorectă',
    containerNumberSelectContainer: 'Selectați containerul',
    manualOperations: 'Operațiuni manuale',
    languageChanged: 'Limba schimbată în',
    truckDelivery: 'mdi-truck-delivery',
    statusOffline: 'Status: offline',
    enterLicencePlate: 'Introduceți numărul de înmatriculare',
    submitLicencePlate: 'Trimiteți numărul de înmatriculare',
    enterIsoCode: 'Introduceți codul ISO',
    submitIsoCode: 'Trimiteți codul ISO',
    enterCustomer: 'Introduceți clientul',
    submitCustomer: 'Trimiteți clientul',
    enterTruckerCompany: 'Introduceți compania de transport',
    submitTruckerCompany: 'Trimiteți compania de transport',
    nonNumericalRelease: 'Eliberare nenumerică',
    filterContainers: 'Filtrează containerele',
    filterTruckerCompany: 'Filtrează compania de transport',
    addNewTruckerCompany: 'Adaugă o nouă companie de transport',
    errorConfirmingGateOut: 'Eroare la confirmarea ieșirii',
    taskAddedSuccessfully: 'Sarcina adăugată cu succes.',
    errorAddingTask: 'Eroare la adăugarea sarcinii',
    referenceNotFound: 'Referință negăsită',
    errorFetchingAvailableContainers: 'Eroare la obținerea containerelor disponibile',
    errorFetchingData: 'Eroare la obținerea datelor',
    errorAddingTruckerCompany: 'Eroare la adăugarea companiei de transport',
    truckerCompanyNameRequired: 'Numele companiei de transport este obligatoriu',
    truckerCompanyEmailRequired: 'Emailul companiei de transport este obligatoriu',
    invalidEmail: 'Adresă de email invalidă',
    containerNotInStock: 'Containerul nu este în stoc',
    errorCheckingContainer: 'Eroare la verificarea containerului',
    infoGateOutContainer: 'Numărul unității containerului este selectat doar pentru eliberări numerice',
  },
};

const i18n = createI18n({
  locale: 'en', // Set default locale
  messages,
});

export default i18n;
